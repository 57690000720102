
// Vue
import { createApp, defineAsyncComponent } from 'vue';

// Pinia
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Stores
import { useSiteStore } from './stores/siteStore';
import { useShopStore } from './stores/shopStore';
import { useGlobalStore } from './stores/globalStore';

// API
import { GetSitedata } from './api/site/getSitedata';

// Components
import VideoBackground from 'vue-responsive-video-background-player';
import YouTube from 'vue3-youtube';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper/core';
import VueClickAway from 'vue3-click-away';
import VueCookies from 'vue3-cookies';

// QD Vue Slider
import { SliderTrack, SliderElement } from "@qd/vue-slider";
import "@qd/vue-slider/dist/style.css";

// Styles
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import 'aos/dist/aos.css';
import { AddPurchasableByBarcode } from './api/cart/addPurchasableByBarcode';

// Declare
declare const window: any;

// App main
const main = async () => {
	const app = createApp({
		delimiters: ['${', '}'],
		components: {
			CVideo: defineAsyncComponent(() => import(/* webpackChunkName: "Video" */ '@/vue/components/Video.vue')),
			CAdviser: defineAsyncComponent(() => import(/* webpackChunkName: "Adviser" */ '@/vue/components/Adviser.vue')),
			CAdviserMenu: defineAsyncComponent(() => import(/* webpackChunkName: "AdviserMenu" */ '@/vue/components/AdviserMenu.vue')),
			CContactForm: defineAsyncComponent(() => import(/* webpackChunkName: "ContactForm" */ '@/vue/components/ContactForm.vue')),
			CEventForm: defineAsyncComponent(() => import(/* webpackChunkName: "EventForm" */ '@/vue/components/EventForm.vue')),
			CFaq: defineAsyncComponent(() => import(/* webpackChunkName: "Faq" */ '@/vue/components/Faq.vue')),
			COpeningHours: defineAsyncComponent(() => import(/* webpackChunkName: "OpeningHours" */ '@/vue/components/OpeningHours.vue')),
			CGridView: defineAsyncComponent(() =>import(/* webpackChunkName: "GridView" */ '@/vue/components/GridView.vue')),
			CSearch: defineAsyncComponent(() =>import(/* webpackChunkName: "Search" */ '@/vue/components/Search.vue')),
			CNewsletterSignup: defineAsyncComponent(() =>import(/* webpackChunkName: "NewsletterSignup" */ '@/vue/components/NewsletterSignup.vue')),
			CNewsletterFooter: defineAsyncComponent(() =>import(/* webpackChunkName: "NewsletterFooter" */ '@/vue/components/NewsletterFooter.vue')),
			
			// Menus
			SiteMenu: defineAsyncComponent(() =>import(/* webpackChunkName: "SiteMenu" */ '@/vue/components/SiteMenu.vue')),
			SideMenu: defineAsyncComponent(() =>import(/* webpackChunkName: "SideMenu" */ '@/vue/components/SideMenu.vue')),
			SiteCart: defineAsyncComponent(() =>import(/* webpackChunkName: "SiteCart" */ '@/vue/components/SiteCart.vue')),
			SiteLogin: defineAsyncComponent(() =>import(/* webpackChunkName: "SiteLogin" */ '@/vue/components/SiteLogin.vue')),
			SiteOrder: defineAsyncComponent(() =>import(/* webpackChunkName: "SiteOrder" */ '@/vue/components/SiteOrder.vue')),
			MegaToast: defineAsyncComponent(() =>import(/* webpackChunkName: "MegaToast" */ '@/vue/components/MegaToast.vue')),
			
			// Search
			Search: defineAsyncComponent(() =>import(/* webpackChunkName: "Search" */ '@/vue/components/Search.vue')),
			MobileSearch: defineAsyncComponent(() =>import(/* webpackChunkName: "MobileSearch" */ '@/vue/components/MobileSearch.vue')),

			// Pages
			Product: defineAsyncComponent(() =>import(/* webpackChunkName: "Product" */ '@/vue/components/Product.vue')),
			Catalog: defineAsyncComponent(() =>import(/* webpackChunkName: "Catalog" */ '@/vue/components/Catalog.vue')),
			Cart: defineAsyncComponent(() =>import(/* webpackChunkName: "Cart" */ '@/vue/components/Cart.vue')),
			CheckoutDetails: defineAsyncComponent(() =>import(/* webpackChunkName: "CheckoutDetails" */ '@/vue/components/checkout/CheckoutDetails.vue')),
			CheckoutSummery: defineAsyncComponent(() =>import(/* webpackChunkName: "CheckoutSummery" */ '@/vue/components/checkout/CheckoutSummery.vue')),
			ConfirmationDetails: defineAsyncComponent(() =>import(/* webpackChunkName: "ConfirmationDetails" */ '@/vue/components/confirmation/ConfirmationDetails.vue')),
			AccountSignup: defineAsyncComponent(() =>import(/* webpackChunkName: "AccountSignup" */ '@/vue/components/account/AccountSignup.vue')),

			// Objects
			ExpandableObject: defineAsyncComponent(() =>import(/* webpackChunkName: "ExpandableObject" */ '@/vue/components/objects/ExpandableObject.vue')),
			ExpandableListObject: defineAsyncComponent(() =>import(/* webpackChunkName: "ExpandableListObject" */ '@/vue/components/objects/ExpandableListObject.vue')),
			AccordionObject: defineAsyncComponent(() =>import(/* webpackChunkName: "AccordionObject" */ '@/vue/components/objects/AccordionObject.vue')),
			VatToggleObject: defineAsyncComponent(() =>import(/* webpackChunkName: "VatToggleObject" */ '@/vue/components/objects/VatToggleObject.vue')),
			NewsletterBoxObject: defineAsyncComponent(() =>import(/* webpackChunkName: "NewsletterBoxObject" */ '@/vue/components/objects/NewsletterBoxObject.vue')),

			StockObject: defineAsyncComponent(() =>import(/* webpackChunkName: "StockObject" */ '@/vue/components/objects/StockObject.vue')),
			FavoriteObject: defineAsyncComponent(() =>import(/* webpackChunkName: "FavoriteObject" */ '@/vue/components/objects/FavoriteObject.vue')),
			PriceObject: defineAsyncComponent(() =>import(/* webpackChunkName: "PriceObject" */ '@/vue/components/objects/PriceObject.vue')),
			ReorderObject: defineAsyncComponent(() =>import(/* webpackChunkName: "ReorderObject" */ '@/vue/components/objects/ReorderObject.vue')),

			//Partials
			SliderPartial: defineAsyncComponent(() =>import(/* webpackChunkName: "SliderPartial" */ '@/vue/components/partials/SliderPartial.vue')),
			Sticky: defineAsyncComponent(() =>import(/* webpackChunkName: "Sticky" */ '@/vue/components/partials/Sticky.vue')),

			Tabs: defineAsyncComponent(() =>import(/* webpackChunkName: "Tabs" */ '@/vue/components/partials/Tabs.vue')),
			Tab: defineAsyncComponent(() =>import(/* webpackChunkName: "Tab" */ '@/vue/components/partials/Tab.vue')),

			// Components
			SliderTrack,
			SliderElement,
		},
		data: () => ({
			scroll_position: 0,
			scroll_direction: '',

			scanner_input: [],
		}),
		methods: {
			scroll: function (event: any) {
				this.scroll_direction = document.body.getBoundingClientRect().top > this.scroll_position ? 'up' : 'down';
				this.scroll_position = document.body.getBoundingClientRect().top;

				switch (this.scroll_direction) {
					case 'down':
						document.body.classList.add('scrolled');
						break;
				
					default:
						document.body.classList.remove('scrolled');
						break;
				}
			},

			addHeaderSecondary: function (event: any) {
				if (siteStore.resize > 768) {					
					document.querySelector('header')?.classList.add('p-header--secondary');
					document.querySelector('nav')?.classList.add('o-nav--secondary');
					document.querySelector('.c-search')?.classList.add('c-search--home');
					document.querySelector('.c-msearch')?.classList.add('c-msearch--home');
				}
			},
			removeHeaderSecondary: function (event: any) {
				if (siteStore.resize > 768) {
					document.querySelector('header')?.classList.remove('p-header--secondary');
					document.querySelector('nav')?.classList.remove('o-nav--secondary');
					document.querySelector('.c-search')?.classList.remove('c-search--home');
					document.querySelector('.c-msearch')?.classList.remove('c-msearch--home');
				}				
			},

			resize()
			{
				siteStore.$patch({ resize: window.innerWidth });
			},

			// Listener for hand scanner input
			scanListener(event: any)
			{
				// If the scanner input is not empty and the last input was more than 10ms ago, clear the input
				//? The scanner will input characters with less then 10ms delay, if we go above 10ms, we will assume the input was made by a human
				if(this.scanner_input.length > 0 && (event.timeStamp - this.scanner_input.slice(-1)[0].timeStamp) > 10){
					this.scanner_input = [];
				}

				// If the key pressed is Enter and the scanner input is not empty, run scan action
				//? All scanners (to my knowledge) will always end the scan with an Enter key
				if(event.key === 'Enter' && this.scanner_input.length > 0){
					// Convert the scanner inputed key to a string
					let scanned = this.scanner_input.reduce((string: any, entry: any) => {
						return string + String(entry.key)
					}, "")

					// Clear the scanner input array
					this.scanner_input = [];

					// Run scanner action
					this.scanAction(scanned);
					return;
				}

				// If the key pressed is not shift, add the key to the scanner input array
				//? Shift as a key will still be recorded by the scanner, but the next upcoming key will be capitalied, therefore we will ignore the shift key.
				if(event.key !== "Shift") {					
					let data = JSON.parse(JSON.stringify(event, ['key', 'timeStamp']))			
					this.scanner_input.push(data)
				}
			},

			// Action to run on scan complete
			async scanAction(string: string)
			{
				//? Currently the only function for the scanner is to add a product to the cart by scanning the barcode
				// Add purchasable to cart by the scanned barcode
				const response = await AddPurchasableByBarcode({
					barcode: string,
				});
			
				if (response.data.success) {						
					shopStore.$patch({ cart: response.data.cart });
				}
				
				globalStore.$patch({ response: response.data });
				globalStore.openToast();
			}
		},

		created() {
			window.addEventListener('scroll', this.scroll, { passive: true });
			window.addEventListener('resize', this.resize, { passive: true });
			window.addEventListener('keydown', this.scanListener);
		},
		destroyed() {
			window.removeEventListener('scroll', this.scroll);
			window.removeEventListener('resize', this.resize);
			window.removeEventListener('keydown', this.scanListener);
		},
		beforeMount() {
			siteStore.$patch({ csrf: window.sitedata.csrf, siteUrl: window.sitedata.siteUrl, account: JSON.parse(window.sitedata.account)});

			GetSitedata().then((res) => {
				shopStore.$patch({ cart: res.data.cart });
				siteStore.$patch({ favorite: res.data.favorite });
			});
		},
		setup() {
			const onSwiper = (swiper: any) => {};
			const onSlideChange = () => {};
			return {
				onSwiper,
				onSlideChange,
				EffectFade,
			};
		},
	});

	SwiperCore.use([Navigation, Pagination, EffectFade]);

	app.use(VueClickAway);
	app.use(VueCookies);
	app.use(pinia);

	// Component liberaries
	app.component('video-background', VideoBackground);
	app.component('YouTube', YouTube);
	app.component('Swiper', Swiper);
	app.component('SwiperSlide', SwiperSlide);

	// Store
	const siteStore = useSiteStore();
	const shopStore = useShopStore();
	const globalStore = useGlobalStore();

	// Mount the app
	return app.mount('#app');
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
